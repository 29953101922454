import { environment } from "../../environments/environment";
   
export const env={
    siteUrl: environment.siteUrl,
    apiUrl:environment.apiUrl,
    siteApiUrl: environment.apiUrl + "public/api/",
    publicUrl: environment.apiUrl + "public/",
    // imageUrl: environment.apiUrl + "public/uploads/",
        imageUrl: environment.apiUrl + "storage/app/public/uploads/",
    articleUrl: environment.apiUrl + "storage/app/articles/",
    chapterXmlUrl: environment.apiUrl + "storage/app/book/",
    bookXmlImageUrl: environment.apiUrl + "storage/app/public/assets/book/",
    coverImageUrl: environment.apiUrl + "storage/app/public/cover_images/book/",
    second_navigation: false,
    articleCoverImage: environment.apiUrl + "storage/app/public/cover_images/journals/",
    defaultImagePath: environment.apiUrl + "storage/app/public/assets/",
    default_journalCoverImage: "default_journal_cover_image.png",
    default_journalLogo: "default_journal_logo.png",
    default_articleCoverImage: "default_article_cover_image.png",
    default_book_cover_image: "default_book_cover_image.png",
    default_part_cover_image: "default_part_cover_image.png",
    default_chapter_cover_image: "default_chapter_cover_image.png",
    default_publisherLogo: "default_publisher_logo.png",
    default_banner_image: "default_banner_image.png",
    articleImage: environment.apiUrl + "storage/",
    downloadFilePath: environment.apiUrl + "public/api/web/article-download-count",
    userProfile: environment.apiUrl + "storage/app/public/images/web_user/",
    editorialImage: environment.apiUrl + "storage/app/public/uploads/editorials/",
    rssFeed: environment.apiUrl + "storage/app/public/feed/rss_feed.xml",
    applicationMode: environment.applicationMode,
    clientName: environment.clientName,
    showAppMode: environment.showAppMode,
    bannerImage: environment.apiUrl + "storage/app/public/banner_images",
    default_article_cover_image_upto_11: environment.apiUrl + "storage/app/public/assets/default_article_cover_image_upto_11.webp",
    default_article_cover_image_above_11: environment.apiUrl + "storage/app/public/assets/default_article_cover_image_above_11.webp",
    journalName: environment.journalName,

}
